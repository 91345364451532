// var stickyHeader = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var inlineNav = false;
// var hoverSensitiveNav = false;
// var megaMenu = false;

// var carouselBackgroundVideoYouTubeID = 'SD12Xug7jbw'; // see https://fresco-docs.netlify.com/#/carousel/background-video

// var countUpStats = false;
// var countUpStatsDuration = 3000;

// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = false;

var feedTitleLinks = ['/Pages/News/', '/Pages/Events/']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

// var sidebarCards = false; // Make sure to set a card layout prefab for '.associatedRelatedPosts .associatedListing' and a card prefab to '.sidebarCard'

var lualBodyClasses = ["light-up-a-life"];
var visualAppealsLoadMore = true;

//  -----------------------------  Move basket to header  ---------------------------  //
$( document ).ready(function() {
//  $( ".homeFeatures" ).wrapInner( "<div class='homeFeaturesInnerWrapper'></div>");
  $('.homeBox1').appendTo( '.homeFeaturesWrapper');
  $('.homefeaturecategory-homeboximpactstats').prependTo( '.homeFeaturesWrapper');
  $('<a href="/Pages/News" class="button buttonArrow">View all</a>').insertAfter('.homeFeedBox1:not(.subsiteFeedBox1) h3.feedsTitle');
  $('<a href="/Pages/Events" class="button buttonArrow">View all</a>').insertAfter('.homeFeedBox2:not(.subsiteFeedBox2) h3.feedsTitle');
  $('ul.footerLinks li.menuAdminBasket').appendTo( 'ul.basketHeader');
});
